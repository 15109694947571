import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const MarinaTowerWrapper = styled.main`
  .bg.high {
    ${mediaMax.phoneXL`
      height: 100vh;
      margin: 7.3% 0 5.2%;
    `}
  }

  .content-row {
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 3.1%;

    ${mediaMax.phoneXL`
      align-items: flex-end;
      flex-direction: column;
      padding: 0px;
    `}
    .img-section {
      width: 100%;
      max-width: 74.5%;

      ${mediaMax.phoneXL`
        width: 100%;
        max-width: 100%;
        padding: 5.2% 0px 7.3%
    `}
    }

    .text-section {
      width: 23%;
      min-width: 300px;
      padding-left: 20px;

      ${mediaMax.phoneXL`
        padding: 0px 9.3% 7.3%;
        width: 100%;
      `}
      h3 {
        padding-bottom: 20px;
        ${mediaMax.phoneXL`
          padding-bottom: 10px;
        `}
      }
    }
  }
`;
import styled from "styled-components";
import {mediaMax, mediaMin} from "../../helpers/MediaQueries";

export const ContentImageTextWrap = styled.div`
  display: flex;
  padding: 9.3% 0;
  position: relative;
  &.lls {
    .reg-text {
      letter-spacing: .2px;
    }
  }
  &.row {
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 3.1%;
    &.t2 {
      padding: 0 85px;
      justify-content: flex-start;
      ${mediaMin.desktopML`
        padding: 0 9.3%;
      `}
    }
    .img-section {
      width: 100%;
      max-width: 74.5%;
    }
    &.t2 {
      .img-section {
        width: 100%;
        max-width: 58.3%;
      }
    }
    .text-section {
      width: 23%;
      min-width: 300px;
      padding-left: 20px;
      h3 {
        padding-bottom: 20px;
      }
    }
    &.t2 {
      .text-section {
        margin-left: 5%;
        width: 20%;
      }
    }
  }

  &.row-rev {
    justify-content: space-between;
    align-items: stretch;
    padding-right: 85px;
    flex-direction: row-reverse;
    ${mediaMin.desktopML`
      padding-right: 9.3%;
    `}

    &.t2 {
      padding-right: 0;
    }
    &.t3 {
      padding: 0 85px;
      align-items: flex-end;
      text-align: center;
      ${mediaMin.desktopML`
        padding: 0 9.3%;
      `}
      
    }
    .img-section {
      width: 100%;
      max-width: 34%;
    }
    &.t2 {
      .img-section {
        width: 100%;
        max-width: 75%;
      }
    }
    &.t3 {
      .img-section {
        width: 100%;
        max-width: 58.3%;
      }
    }
    .text-section {
      width: 40%;
      min-width: 300px;
      padding-left: 13.3%;
      h3 {
        padding-bottom: 20px;
      }
    }
    &.t2 {
      .text-section {
        width: 21.5%;
        min-width: 200px;
        padding-left: 5.2%;
        padding-top: 0;
      }
    }
    .bg {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 18vh;
      ${mediaMax.desktopM`
        bottom: 14vh;
      `}
      ${mediaMax.desktop`
        width: 100%;
        bottom: 9vh;
      `}
      ${mediaMax.desktopSmall`
        width: 100%;
        bottom: 0;
      `}
    }
  }
  &.col {
    align-items: flex-start;
    flex-direction: column;
    padding: 9.3% 9.3% 0;
    .img-section {
      width: 100%;
    }
    .text-section {
      width: 40%;
      min-width: 300px;
      padding: 50px 0;
      h3 {
        padding-bottom: 20px;
        ${mediaMax.tablet`
          padding-bottom: 10px;          
        `};
      }
    }
  }
  &.end {
    align-items: flex-end;
    padding: 0;
    .text-section {
      width: 48%;
      min-width: 400px;
      padding: 50px 9.3% 50px 4%;
      ${mediaMax.tabletLandscape`
          width: 70%;        
      `};
      h3 {
        padding-bottom: 20px;
        ${mediaMax.tablet`
          padding-bottom: 10px;          
        `};
      }
    }
  }

  ${mediaMax.tablet`
    &.row, &.row-rev, &.row-rev.t2, &.row-rev.t3, &.col, &.row.t2, &.end {
      flex-direction: column;
      padding: 0;
      .img-section {
        width: 100%;
        max-width: 100%;
        padding: 7.3% 5.2%;
      }
      .text-section {
        width: 100%;
        min-width: 300px;
        padding: 0 9.3% 7.3%;
      }
    }
    &.row-rev.t3, &.row.t2 {
      .img-section {
        padding: 0 0 7.3%;
      }
      .text-section {
        padding: 0 9.3% 0;
        text-align: left;
      }
    }
    &.row-rev.t4, &.col.end {
      .img-section {
        padding: 7.3% 0;
      }
      .text-section {
        padding: 0 9.3% 7.3%;
      }
    }
    &.col.end.t1 {
     .text-section {
       padding: 0 9.3%;
     }
     .reg-text {
       letter-spacing: .3px;
     }
    }
    &.lpt {
      .img-section {
        padding-top: 5.2%!important;
      }
    }
    &.lpb {
      .img-section {
        padding-bottom: 5.2%!important;
      }
    }
  `};
`;

import React, { FC, useEffect, useRef } from "react";
import Image from "../image";
import { ContentImageTextWrap } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IContentImgText {
  imgName: string;
  altText?: string;
  heading?: string | React.ReactElement;
  bg?: string;
  text: string | React.ReactElement;
  type: string;
}

const ContentImageText: FC<IContentImgText> = ({
  type,
  imgName,
  altText,
  heading,
  text,
  bg,
}) => {
  const ContentWrapRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          imgRef?.current || null,
          2.5,
          { opacity: 0, ease: 'Power.easeInOut' },
          { opacity: 1, ease: 'Power.easeInOut' },
        )
        .fromTo(
          textRef?.current || null,
          .5,
          { opacity: 0, y: 40, ease: 'Power.easeInOut' },
          { opacity: 1, y: 0, ease: 'Power.easeInOut' },
          "-=1.8"
        ),
    });
  }, []);
  return (
    <ContentImageTextWrap className={type} ref={ContentWrapRef}>
      <div className={"img-section"} ref={imgRef}>
        <Image
          imageName={imgName}
          altText={altText || "The Ritz-Carlton Residences"}
        />
      </div>
      <div className={"text-section"} ref={textRef}>
        {heading && <h3 className={"heading fs-xl"}>{heading}</h3>}
        {text && <p className={"reg-text"}>{text}</p>}
        {bg && (
          <div className={"bg"}>
            <Image
              imageName={bg}
              altText={altText || "The Ritz-Carlton Residences"}
            />
          </div>
        )}
      </div>
    </ContentImageTextWrap>
  );
};
export default ContentImageText;

import * as React from "react";
import { seoData } from "../data/seo";
import { introData } from "../data/intro";
import { navData } from "../data/nav";
import { textData } from "../data/text";
import { accordionData, accordionLabel } from "../data/accordion";
import useWindowSize from "../helpers/UseWindowSize";
import Seo from "../components/seo";
import IntroVideo from "../components/intro-video";
import Image from "../components/image";
import ContentNextPage from "../components/content-next-page";
import Footer from "../components/footer";
import Composition from "../components/composition";
import ContentText from "../components/content-text";
import ContentAccordion from "../components/content-accordion";
import ContentImageText from "../components/content-img-text";
import NewComposition from "../components/composition/test";
import {CompositionWrapper} from "../components/composition/test/styled.index";
import { MarinaTowerWrapper } from "../components/marina-tower/index.styled";
import leftPalms from "../assets/images/compos/marina_compos_1_left.png";
import rightPalms from "../assets/images/compos/marina_compos_1_right.png";
import leftPalms3 from "../assets/images/compos/marina_compos_3_left.png";
import rightPalms3 from "../assets/images/compos/marina_compos_3_right.png";
import rightPalms4 from "../assets/images/compos/marina_compos_4_right.png";
import leftMFooterM from "../assets/images/compos/marina-compos_mob_0_left.png";
import rightMFooterM from "../assets/images/compos/marina-compos_mob_0_right.png";

const MarinaTowerPage = () => {
  const {width} = useWindowSize();
  if (typeof window === `undefined`) {
    return(<></>);
  }
  return (
    <MarinaTowerWrapper>
      <Seo page={seoData.marinaTower} />
      <IntroVideo page={introData.marinaTower} />
      <section>
        <CompositionWrapper>
          <ContentText
            bgClassName={"bg high"}
            heading={textData.marinaTower1.heading}
            text={textData.marinaTower1.text}
          />
          {width < 510 && <Composition imgName={"marina-tower-compos_1_1_mob.png"} align={"top"} />}
          {width < 510 && <Composition imgName={"marina-tower-compos_1_mob.png"} align={"offBottom5"} />}
          {width >= 1024 && <NewComposition imgSrc={leftPalms} top={width > 1200 ? "5vh" : '15vh'} left={'0'} height={width >= 1200? '117vh' : "100vh"}/>}
          {width >= 1024 && <NewComposition imgSrc={rightPalms} bottom={width >= 1300? "25vh" : "18vh"} right={width >= 1300? "0" : "-8%"} height={'75vh'}/>}
        </CompositionWrapper>

        <Image
          imageName={"marina-tower_01.jpg"}
          altText={"The Marina Tower"}
          padding={"0"}
          move={true}
        />
        <ContentImageText
          type={width >= 768 ? "row-rev" : "row-rev t4 lpt" }
          imgName={"marina-tower_02.jpg"}
          bg={width >= 1024 ? "marina-tower-compos_2.png" : ''}
          text={textData.marinaTower2.text}
        />
      </section>
      <Image
        imageName={"marina-tower_03.jpg"}
        altText={"The Marina Tower"}
        padding={width >= 600 ? '0 0 5.3%' : "0 0 7.3%"}
        move={true}
      />
      <CompositionWrapper>
        {width >= 1024 && <NewComposition shape={"circle"} top={"10vh"} left={width >= 1300? "-65vh" : "-68vh"} height={'77vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={leftPalms3} top={width >= 1400? "80vh" : "85vh"} left={width >= 1300? "0" : "-5%"} height={'24vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightPalms3} top={'0'} right={width >= 1400? '0' : "-5%"} height={width >= 1400? '90vh' : "75vh"}/>}
        {width < 600 && <Composition imgName={"marina-tower-compos_5_mob.png"} align={"offTop2"} />}
        {width < 600 && <NewComposition imgSrc={leftPalms3} top={"310px"} left={'0'} height={'13vh'}/>}
        <ContentAccordion
          label={accordionLabel.marinaTowerAmenities.label}
          accData={accordionData.marinaAmenities}
        />
        <Image
          imageName={"marina-tower_04.jpg"}
          altText={"The Marina Tower"}
          padding={width >= 1885 ? "9.3%" : "9.3% 85px"}
          paddingMob={"0 5.2%"}
          move={false}
        />
      </CompositionWrapper>
      <ContentImageText
        type={"col end lpt"}
        imgName={"marina-tower_05.jpg"}
        text={textData.marinaTower3.text}
      />
      <Image
        imageName={"marina-tower_06.jpg"}
        altText={"The Marina Tower"}
        padding={width >= 1885 ? "0 9.3%" : "0 85px"}
        paddingMob={"0 5.2%"}
        move={false}
      />
      <ContentImageText
        type={"content-row"}
        imgName={"marina-tower_07.jpg"}
        heading={textData.marinaTower4.heading}
        text={textData.marinaTower4.text}
      />
      <Image
        imageName={"marina-tower_08.jpg"}
        altText={"The Marina Tower"}
        padding={width >= 1885 ? "0 9.3%" : "0 85px"}
        paddingMob={"0 5.2%"}
        move={false}
      />
      <Image
        imageName={"marina-tower_09.jpg"}
        altText={"The Marina Tower"}
        padding={'9.3% 0'}
        paddingMob={"5.2% 0"}
        move={true}
      />

        <section>
          <ContentNextPage
            nextLink={navData.marinaTowerRes.link}
            nextTitle={navData.marinaTowerRes.next}
            mobHeight={"420px"}
            textAlign={'center'}
            vertAlign={"center"}
          />
          {width < 600 && <NewComposition imgSrc={leftMFooterM} bottom={"-20px"} left={width < 389 ? "-10px" : "-5px"} height={'88vw'}/>}
          {width < 600 && <NewComposition imgSrc={rightMFooterM} bottom={"30px"} right={"0"} height={width > 400 ? '90vw' : '88vw'}/>}
        </section>
        <Footer />
        {width >= 1024 && <NewComposition imgSrc={leftPalms} bottom={width >= 1300? "20vh" : '29vh'} left={width >= 1200? "0" : "-3%"} height={width >= 1300? '105vh' : '100vh'}/>}
        {width >= 1024 && <NewComposition imgSrc={rightPalms4} bottom={width >= 1600? "285px" : "350px"} right={width >= 1200? "0" : "0"} height={width >= 1300? '72vh' : "85vh"}/>}
    </MarinaTowerWrapper>
  );
};

export default MarinaTowerPage;
